body.single-service {
  article {
    background: url("../../images/concreat1.jpg");

    .service {
      background-color: #4082b3;
      display: block;
      color: white;
      height: 400px;
      position: relative;

      @media screen and (max-width: 768px) {
        height: unset;
        padding-bottom: 3rem;
      }

      h2 {
        font-size: 2rem;
        font-weight: 400;
      }


      p {
        font-size: 1.1rem;
        font-weight: 300;
        line-height: 1.3;
      }
    }

    .cms {
      background-color: white;
    }
  }

  .services {
    padding-bottom: 7em;
    border-top: 1em solid #33719e;

    .col {
      max-width: 400px;
    }
  }

  .gallery-item {

    .has-texts {

      @media screen and (min-width: 992px) {
        padding-left: 1rem;
        padding-right: initial;
      }
    }

    .cms {
      height: 100%;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
      color: white;

      h3 {
        font-size: 2em;
        font-weight: 500;
      }

      p {
        font-size: 1.1em;
      }

      a {
        color: white;
      }
    }

    .has-cards {
      background-color: rgba(255, 255, 255, 1);
      padding-bottom: 0.5rem;
      height: 100%;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        padding-top: 0.5rem;
      }

      .card {
        border: 0;
        background: transparent;
        margin: 0.5rem 0 0;
        flex: 0 0 50%;
        padding: 0 0.5em 0.5em 0.5em;

        @media screen and (max-width: 768px) {
          margin: 0;
          padding: 0.25rem 0;
          flex: 0 0 100%;
        }

        img {
          display: inline-block;
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }

      .card:nth-child(1){
        padding: 0.5rem;
        @media screen and (max-width: 768px) {
          padding: 0.25rem 0;
        }
      }

      .card:nth-child(2){
        padding: 0.5rem;
        @media screen and (max-width: 768px) {
          padding: 0.25rem 0;
        }
      }
    }

    &:nth-child(even) {
      .has-texts {
        @media screen and (min-width: 992px) {
          padding-left: initial;
          padding-right: 1rem;
        }
      }

      > .row {
        flex-direction: row-reverse;
      }
    }
  }

  .gallery-item:nth-child(odd) {
    .cms {
      background-color: #0099cc;
    }

    @media screen and (max-width: 768px) {
      .has-cards {
        background-color: #0099cc;
      }
    }

  }

  .gallery-item:nth-child(even) {
    .cms {
      background-color: #33719e;
    }

    @media screen and (max-width: 768px) {
      .has-cards {
        background-color: #33719e;
      }
    }
  }
}