body.articles {
  background: url("../../images/concreat1.jpg");

  .article {
    background: #fff;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
    margin-bottom: 1.25rem;
    position: relative;

    time {
      display: block;
      font-size: 0.9em;
    }

    h3 {
      color: #33719e;
      font-size: 1.2em;
      line-height: 1.3;
      padding: 1rem 1rem 0;
    }

    p {
      margin-bottom: 0;
      line-height: 1.3;
      font-size: 0.9rem;
      font-weight: 300;
      text-align: justify;
    }
  }
}