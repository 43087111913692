nav.top-nav {
  background-color: #666666;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;


  @media screen and (max-width: 768px) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #333333;
  }

  .hamburger {
    display: none;
    width: 2em;
    cursor: pointer;
    position: relative;
    margin: 1em;

    @media (max-width: 768px) {
      display: unset;
    }

    span {
      display: block;
      height: 0.175rem;
      margin: 0.45rem 0;
      background-color: #eaeaea;
      transition: all 0.25s;
      transform: rotate(0);
      position: relative;
      right: 0;
    }

    &.active {
      span {
        &:first-child {
          transform: rotate(-45deg);
          height: 0.15rem;
          margin-bottom: -3rem;
        }

        &:nth-child(2) {
          transition: all 0.01s;
          opacity: 0;
        }

        &:last-child {
          transform: rotate(45deg);
          height: 0.15rem;
          margin-top: 2.2rem;
        }
      }
    }

  }

  .contact-links {

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    color: white;

    a[href='#contact'] {
      background-color: #0099cc;
    }

    a[href^='tel:'] {
      background-color: #33719e;

      span {
        font-size: 1.5em;
        font-weight: bold;
      }
    }
  }

  ul {
    color: white;
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 3rem;
    font-size: 1.5rem;


    @media screen and (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      visibility: hidden;
      top: 63px;
      height: calc(100vh - 54px);
      width: 250px;
      padding: 40px 0;
      background-color: #222222;
      transition: all 0.25s;
      right: -250px;
      color: white;

      &.active {
        visibility: visible;
        right: 0;
      }
    }

    li {
      height: 100%;

      @media screen and (max-width: 768px) {
        height: auto;
        width: 100%;
      }

      a {
        padding: 0 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      &.active {
        background-color: #555555;
      }

      &:hover {
        background-color: #787878;
      }
    }
  }


  .logo {
    background-color: white;
    position: absolute;
    left: 3rem;
    top: 0;
    padding: 0.5rem 0;

    @media screen and (max-width: 768px) {
      position: static;
      padding: 0.25rem 1rem;
      width: 100%;
    }

    img {
      width: calc(100% - 2rem);
      margin: 0 auto;
      display: block;

      @media screen and (max-width: 768px) {
        width: auto;
        height: 55px;
      }
    }
  }
}