#contact {
  background-color: #2c6087;
  color: white;

  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }

  .boat {
    display: none;
    font-size: 1.5rem;
    border-top: 3px solid #e0a243;

    @media screen and (max-width: 768px) {
      display: flex;
    }

    > * {
      flex: 0 0 50%;

      &:first-child {
        background-color: #33719e;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .boat-bridge {
      transition: all 0.5s;
      visibility: hidden;
      height: 0;
      overflow: auto;

      &.active {
        visibility: visible;
        height: calc(100vh - 104px);
      }
    }
  }


  > .container {
    max-width: 700px;
  }

  .h3 {
    font-size: 2rem;
    color: #a9dfeb;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 6.5ch;
      background-color: #e0a243;
      bottom: 1rem;
      left: 49%;
      transform: translateX(-50%);
    }
  }

  .h5 {
    font-size: 1.5rem;
    font-weight: 300;

    @media screen and (max-width: 768px) {
      font-size: 1.25em;
      line-height: 1.3;
    }
  }

  form {
    label {
      margin-bottom: 0;
      font-size: 1rem;
    }

    .form-control {
      font-size: 1.1rem;
      border: 0;
    }

    textarea {
      height: 7.8rem;
      margin-bottom: 2.55rem;
    }

    button {
      background-color: #e0a243;
      color: white;
      width: 100%;
      border: 0;
      font-weight: bold;
      font-size: 1.5rem;
      padding: 0.14rem;
    }
  }

  .c-i {
    > :first-child {
      color: #a9dfeb;
      font-size: 1.25em;
    }

    > :last-child {
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
}