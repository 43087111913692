// Fonts
@import "heebo";
// Variables
@import 'variables';
// Bootstrap
//@import '~bootstrap/scss/bootstrap';
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~slick-carousel/slick/slick";
@import "~magnific-popup/dist/magnific-popup.css";


.ltr {
  direction: ltr;
}


body {
  text-align: right;
  padding-top: 58px;
}

img {
  max-width: 100%;
}

a:not(.is-link) {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.cms a {
  color: #3490dc;

  &:hover {
    color: #1d68a7;
    text-decoration: underline;
  }
}

.concrete-bg {
  background: url("../images/concreat1.jpg");
}

.whatsapp-link {
  position: fixed;
  bottom: 1rem;
  left: 1rem;

  @media screen and (max-width: 992px){
    bottom: 3rem;
    left: 0.5rem;
  }

  &:hover {
    .whatsapp {
      fill: #18766a;
    }
  }

  .whatsapp {
    fill: #2cc64e;
    transition: fill 1s ease-in-out;
  }
}

@import "partials/navbar";
@import "partials/contact";
@import "partials/footer";
@import "partials/header";
@import "partials/services";

@import "pages/home";
@import "pages/about";
@import "pages/articles";
@import "pages/article";
@import "pages/service";
@import "pages/gallery";
@import "pages/video";