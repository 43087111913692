img.mfp-img {
  user-select: none;
}

body.video {

  .has-cards {
    background-color: rgba(255, 255, 255, 1);
    padding-bottom: 0.5rem;
    height: 100%;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      padding-top: 0.5rem;
    }

    .card {
      border: 0;
      background: transparent;
      margin: 0.5rem 0 0;
      flex: 0 0 50%;
      padding: 0 0.5em 0.5em 0.5em;

      @media screen and (max-width: 768px) {
        margin: 0;
        padding: 0.25rem 0;
        flex: 0 0 100%;
      }

      img {
        display: inline-block;
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }

    .card:nth-child(1) {
      padding: 0.5rem;
      @media screen and (max-width: 768px) {
        padding: 0.25rem 0;
      }
    }

    .card:nth-child(2) {
      padding: 0.5rem;
      @media screen and (max-width: 768px) {
        padding: 0.25rem 0;
      }
    }
  }

  &:nth-child(even) {
    > .row {
      flex-direction: row-reverse;
    }
  }

}