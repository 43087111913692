header {
  background: white;
  color: #33719e;

  @media screen and (max-width: 768px) {
    &.py-5 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  }

  .breadcrumb {
    background: inherit;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 1.3rem;

    .breadcrumb-item + .breadcrumb-item::before {
      padding-left: 0;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      padding: 0 0.5rem;
    }
  }

  h1 {
    font-size: 3.5em;
    font-weight: 500;
    line-height: 1;

    @media screen and (max-width: 768px) {
      font-size: 2em;
    }

  }
}