.services {
  background: url("../../images/concreat2.jpg");
  padding-top: 5rem;
  padding-bottom: 15rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 7rem;
  }


  .services-box {

    > div {
      @media screen and (max-width: 768px) {
        padding: 7px;
      }
    }

    .service {
      background-color: #4082b3;
      display: block;
      color: white;
      height: 400px;
      position: relative;
      transition: all 0.25s;

      @media screen and (max-width: 768px) {
        height: unset;
        padding-bottom: 2em;
        margin-bottom: 4em;
      }

      .icon {
        margin: 0 auto;

        @media screen and (max-width: 768px) {
          width: 100px;
        }

      }


      h2 {
        font-size: 2rem;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 1.75em;
        }
      }


      p {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.3;

        @media screen and (max-width: 768px) {
          font-size: 1em;
        }

      }

      .go {
        position: absolute;
        bottom: -45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background: inherit;
        border-radius: 972px;
        padding: 0.5rem;
        transition: all 0.25s;

      }

      &:hover {
        transform: scale(1.05);

        .go {

        }
      }
    }

    &:nth-child(2) {
      .service {
        background-color: #326f9d;
      }
    }

    &:nth-child(3) {
      .service {
        background-color: #295b81;
      }
    }

    &:nth-child(4) {
      .service {
        background-color: #244e6e;
      }
    }
  }

}