body.home {
  .intro {

    .slider {
      img {
        max-width: 100%;
        width: 865px;
        height: 465px;
        display: block;

        @media screen and (max-width: 768px) {
          height: unset;
        }

      }
    }

    > .row.no-gutters {
      background-color: #f6f6f6;
    }

    .has-content {

      h1 {
        color: #265475;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 1.3;
        position: relative;

        @media screen and (max-width: 768px) {
          font-size: 2.5em;
        }

        &:after {
          content: '';
          position: absolute;
          height: 1rem;
          width: 4ch;
          background-color: #e0a243;
          bottom: 1rem;
          left: 50%;
          transform: translateX(-50%) skewX(-35deg);

          @media screen and (max-width: 768px) {
            height: 0.5rem;
          }
        }
      }

      p {
        font-size: 2rem;
        line-height: 1.3;
        max-width: 500px;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
          font-size: 1.3rem;
          padding: 1rem;
        }
      }
    }
  }

  .points {
    position: relative;

    > .container {
      margin-top: -5rem;
      background-color: white;
    }

    .h1 {
      color: #1d68a7;
      font-size: 2.5rem;
      font-weight: 400;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.3;
      @media screen and (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    .ul {
      font-size: 2rem;
      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
      }

      .li {
        position: relative;

        &:before {
          content: url("../../images/v.png");
          background-color: #33719e;
          border-radius: 972px;
          width: 35px;
          height: 35px;
          display: inline-block;
          text-align: center;
          line-height: 1.1;
        }

      }
    }

    .has-image {
      position: relative;
      padding: 1rem;

      img {
        display: block;
        margin: 0 auto;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-top: 5px solid #458dc2;
        border-left: 5px solid #458dc2;
        width: 2rem;
        height: 2rem;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 5px solid #e0a243;
        border-right: 5px solid #e0a243;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .about {
    background-color: #f0f0f0;

    .has-image {
      position: relative;
      padding: 1rem;

      @media screen and (max-width: 768px){
        max-width: 380px;
        margin: 0 auto;
      }

      img {
        display: block;
        margin: 0 auto;

        @media screen and (max-width: 768px){
          max-width: 70%;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-top: 5px solid #e0a243;
        border-left: 5px solid #e0a243;
        width: 2rem;
        height: 2rem;


        @media screen and (max-width: 768px) {
          left: 3rem;
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 5px solid #e0a243;
        border-right: 5px solid #e0a243;
        width: 2rem;
        height: 2rem;

        @media screen and (max-width: 768px) {
          right: 3rem;
        }
      }
    }

    h3 {
      font-size: 2rem;
      color: #2c6087;
      font-weight: 500;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }

  .clients {
    h3 {
      color: #2c6087;
      font-weight: 400;
      font-size: 2.5rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 5ch;
        background-color: #e0a243;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .client {
      @media screen and (max-width: 768px) {
        flex: 0 0 33.333%;
      }
    }
  }
}