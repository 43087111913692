body.about {
  article {
    background: url("../../images/concreat1.jpg");

    .about-wrapper {

      .a-box {
        position: relative;
        background-color: #fff;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);

        img {
          width: 100%;
        }
      }

      .cms {

        h3 {
          font-size: 2rem;
          color: #33719e;
        }

        p {
          font-size: 1.1rem;
          line-height: 1.3;
          font-weight: 300;
        }
      }
    }
  }
}